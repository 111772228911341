define([
    'lodash',
    'image-client-api',
    'layout/specificComponents/imageLayout',
    'warmupUtilsLib'
], function (
    _,
    imageClientLib
) {
    'use strict';

    const alignTypes = imageClientLib.alignTypes;
    const videoPositions = {
        [alignTypes.CENTER]: positionOptions => ({left: positionOptions.horizontalMiddle, top: positionOptions.verticalMiddle}),
        [alignTypes.LEFT]: positionOptions => ({left: 0, top: positionOptions.verticalMiddle}),
        [alignTypes.RIGHT]: positionOptions => ({left: positionOptions.horizontalRight, top: positionOptions.verticalMiddle}),
        [alignTypes.TOP]: positionOptions => ({left: positionOptions.horizontalMiddle, top: 0}),
        [alignTypes.BOTTOM]: positionOptions => ({left: positionOptions.horizontalMiddle, top: positionOptions.verticalBottom}),
        [alignTypes.TOP_LEFT]: () => ({left: 0, top: 0}),
        [alignTypes.TOP_RIGHT]: positionOptions => ({left: positionOptions.horizontalRight, top: 0}),
        [alignTypes.BOTTOM_LEFT]: positionOptions => ({left: 0, top: positionOptions.verticalBottom}),
        [alignTypes.BOTTOM_RIGHT]: positionOptions => ({left: positionOptions.horizontalRight, top: positionOptions.verticalBottom})
    };

    function getVideoQualityBySize(qualities, width, height) {
        const uniqQualities = _.uniqBy([...qualities].reverse(), 'size').reverse();
        const targetQuality = _.find(uniqQualities, value => value.size > width * height) || _.last(qualities);
        return targetQuality.quality;
    }

    function getScaleFactor(containerWidth, containerHeight, videoWidth, videoHeight) {
        return {wScale: containerWidth / videoWidth, hScale: containerHeight / videoHeight};
    }

    function getVideoDimension(fittingType = imageClientLib.fittingTypes.SCALE_TO_FILL, videoScale, videoWidth, videoHeight) {
        let scale;
        switch (fittingType) {
            case imageClientLib.fittingTypes.SCALE_TO_FILL:
                scale = Math.max(videoScale.wScale, videoScale.hScale);
                break;
            case imageClientLib.fittingTypes.SCALE_TO_FIT:
                scale = Math.min(videoScale.wScale, videoScale.hScale);
                break;
        }
        return {
            width: Math.round(videoWidth * scale),
            height: Math.round(videoHeight * scale)
        };
    }

    function getVideoPosition(alignType = imageClientLib.alignTypes.CENTER, videoSize, videoContainerSize) {
        const {width: containerWidth, height: containerHeight} = videoContainerSize;
        const {width: videoWidth, height: videoHeight} = videoSize;
        return videoPositions[alignType]({
            verticalMiddle: Math.round((containerHeight - videoHeight) / 2),
            horizontalMiddle: Math.round((containerWidth - videoWidth) / 2),
            verticalBottom: containerHeight - videoHeight,
            horizontalRight: containerWidth - videoWidth
        });
    }

    function measureBgVideo(parentId, id, measureMap, nodesMap) {
        const videoWrapper = nodesMap[id];
        const videoNode = nodesMap[`${id}video`];
        measureMap.custom[id] = {};

        const {isVideoDataExists, videoWidth, videoHeight, qualities} = nodesMap[id].dataset;
        const {fitting, align, hasBgEffect} = videoWrapper.parentElement.dataset;
        if (isVideoDataExists) {
            // in cases were background has a full height scroll effect we use the parent width ,
            // the element position is fixed and without explicit width he will take the window width
            const qualitiesMeasures = JSON.parse(qualities);
            const width = hasBgEffect ? measureMap.width[parentId] : measureMap.width[id];
            const height = measureMap.height[id];
            const vidWidth = parseInt(videoWidth, 10);
            const vidHeight = parseInt(videoHeight, 10);
            const scaleFactor = getScaleFactor(width, height, vidWidth, vidHeight);
            const videoScaledDimension = getVideoDimension(fitting, scaleFactor, vidWidth, vidHeight);
            const videoPosition = getVideoPosition(align, videoScaledDimension, {width, height});
            const videoStyle = {
                width: videoScaledDimension.width,
                height: videoScaledDimension.height,
                left: videoPosition.left,
                top: videoPosition.top
            };
            measureMap.custom[id] = {
                isVideoDataExists,
                qualities: qualitiesMeasures,
                videoNode,
                videoStyle
            };
            measureMap.width[`${id}video`] = videoStyle.width;
            measureMap.height[`${id}video`] = videoStyle.height;
            measureMap.top[`${id}video`] = videoStyle.top;
            measureMap.left[`${id}video`] = videoStyle.left;
        } else {
            measureMap.custom[id] = {
                videoNode,
                isVideoDataExists
            };
        }
    }

    function patchBgVideo(id, patchers, measureMap) {
        const customMeasure = measureMap.custom[id];

        if (customMeasure.isVideoDataExists) {
            const {videoStyle, qualities} = customMeasure;
            patchers.attr(`${id}video`, {
                width: videoStyle.width,
                height: videoStyle.height,
                'data-quality': getVideoQualityBySize(qualities, videoStyle.width, videoStyle.height)
            });
            patchers.css(`${id}video`, videoStyle);
        } else {
            //clear video
            patchers.attr(`${id}video`, {src: ''});
            customMeasure.videoNode.load();
        }
    }

    return {
        patchBgVideo,
        measureBgVideo
    };
});
